.react-calendar {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: -webkit-fill-available;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 2em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  outline: none;
  border-radius: 20px;
  margin: 5px 0px;
  border: solid 2px var(--white);
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;

}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border: 0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  border: 0;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover {
  background-color: #e6e6e6;
}

.react-calendar__tile:enabled:focus {
}

.react-calendar__tile--now {
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  color: var(--dark)
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.highlight {
  background: var(--calendarDateReserved);
}

/* Blocked day / Unavailable => id=0 */
.calendarDayBlocked {
  background: var(--explosive);
  color: var(--white);
  font-weight: bold;
}

/* Filled day => id=1 */
.calendarDayHasReservations {
  background: var(--danger25);
  color: var(--danger);
  font-weight: bold;
}

/* User reservations => id=2 */
.calendarDayFilled {
  background: var(--primary);
  color: var(--white);
  font-weight: bold;
}

/* Has at least one reservation => id=3 */
.calendarDayUserReservation {
  background: var(--primary25);
  font-weight: bold;
}
